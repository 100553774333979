import { Link } from "@reach/router";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const ExpiredCredits = () => {
  const { clearModal } = useContext(ModalContext);

  return (
    <div className="container-fluid px-0">
      <p>
        Lo sentimos. Has agotado todos tus créditos para reservar Clases
        Presenciales. Puedes comprar más en Paquetes.
      </p>
      <p className="bold">
        Después de comprar, debes regresar a reservar tu clase.
      </p>
      <Link
        onClick={clearModal}
        to="mybunnation/paquetes"
        className="btn w-100 btn-primary"
      >
        Ir a Paquetes
      </Link>
    </div>
  );
};

export default ExpiredCredits;